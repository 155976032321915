.sidebar {
  max-width: 250px;
  width: 100%;
  border-radius: 0px 5px 5px 0px;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 100vh;
  background-color: #171717;
  box-shadow: 0.25rem 0rem 1rem rgba(0, 0, 0, 0.1);
  border: 0px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  transition: 0.2s all ease;
}

.sidebar.active {
  left: 0px;
}

.sidebar img {
  width: 150px;
  margin: 0px auto;
  margin-bottom: 50px;
}

.sidebar .navlink {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
}

.sidebar .navlink a {
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.sidebar .navlink button.transparent {
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.sidebar .navlink svg {
  color: #ffffff;
}

.sidebar .navlink.active a,
.sidebar .navlink.active svg {
  color: #f1b637;
}

@media only screen and (max-width: 1023px) {
  .sidebar {
    left: -250px;
  }
}

nav {
  width: max-content;
  position: fixed;
  right: 0px;
  top: 0px;
  display: none;
}

.nav-wrapper {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .nav-wrapper .dark {
  width: 36px;
  height: 36px;
  color: #8e9195;
  padding: 0px;
}

@media only screen and (min-width: 1024px) {
  .sidebar nav {
    display: none;
  }
}
