.file-upload {
  display: inline-block;
}

.custom-file-upload {
  display: inline-block;
  background-color: #171717;
  color: #fff;
  padding: 10px 20px;
  /* border: 1px solid red; */
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

input[type="file"] {
  display: none;
}
