.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}

/* .table-wrapper tr.centered-tr td {
  text-align: center;
} */
